import React, { Suspense } from "react";
import { useAuth } from "./context/auth-context";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./themes/blue";

import Layout from "./components/Layout";
import { SnackBarProvider } from "./context/snackbar";

const AppAuthenticated = React.lazy(() => import("./AppAuthenticated"));
const AppUnauthenticated = React.lazy(() => import("./AppUnauthenticated"));

function App(props) {
  const { user } = useAuth();

  const { container } = props;

  return (
    <ThemeProvider theme={theme}>
      <SnackBarProvider>
        <Layout container={container}>
          <Suspense fallback={<div>loading... </div>}>{user ? <AppAuthenticated /> : <AppUnauthenticated />}</Suspense>
        </Layout>
      </SnackBarProvider>
    </ThemeProvider>
  );
}

export default App;
