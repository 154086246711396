import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { NavLink } from "react-router-dom";

export default () => {
  return (
    <List
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Timesheets
        </ListSubheader>
      }
    >
      <ListItem
        data-testid="menuTimesheetsCurrent"
        button
        key="timesheetsCurrent"
        component={NavLink}
        activeClassName="Mui-selected"
        to="/timesheets?status=current"
        isActive={(match, location) => location.search === `?status=current`}
      >
        <ListItemText primary="Current (?)" />
      </ListItem>
      <ListItem
        data-testid="menuTimesheetsPayroll"
        button
        key="timesheetsPayroll"
        component={NavLink}
        activeClassName="Mui-selected"
        to="/timesheets?status=payroll"
        isActive={(match, location) => location.search === `?status=payroll`}
      >
        <ListItemText primary="Payroll (?)" />
      </ListItem>
      <ListItem
        data-testid="menuTimesheetsUnapproved"
        button
        key="timesheetsUnapproved"
        component={NavLink}
        activeClassName="Mui-selected"
        to="/timesheets?status=unapproved"
        isActive={(match, location) => location.search === `?status=unapproved`}
      >
        <ListItemText primary="Unapproved (?)" />
      </ListItem>
      <ListItem
        data-testid="menuTimesheetsAll"
        button
        key="timesheetsAll"
        component={NavLink}
        activeClassName="Mui-selected"
        to="/timesheets?status=all"
        isActive={(match, location) => location.search === `?status=all`}
      >
        <ListItemText primary="All" />
      </ListItem>
    </List>
  );
};
