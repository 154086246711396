import React, { useState, useEffect, createContext } from "react";
import Axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { configure } from "axios-hooks";
import { useHistory, useLocation } from "react-router-dom";

const AuthContext = createContext();

let accessToken;

try {
  accessToken = JSON.parse(localStorage.getItem("tokens")).access_token;
} catch (err) {
  console.log("no access token");
}

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer " + accessToken,
    Accept: "application/json"
  }
});

configure({ axios });

function AuthProvider(props) {
  const [user, setUser] = useState();
  let history = useHistory();

  const location = useLocation();

  console.log(location)

  // const { user} = data;
  const { username } = user || {};

  axios.interceptors.response.use(
    function(response) {
      // Do something with response data
      // console.log("respons inter");
      return Promise.resolve(response);
    },
    function(err) {
      //  TODO proper logging
      // console.log("hitting inteceptr", err.response);

      switch (err.response && err.response.status) {
        case 401:
          accessToken = null;
          localStorage.setItem("tokens", null);
          setUser();
          history = ["/login"];
          break;
        case 404:
        case 422:
          if (err.response.data.message) err.message = err.response.data.message;
          return Promise.reject(err);
          break;
        default:
          // Do something with response err
          break;
      }

      return Promise.reject(err);
    }
  );

  const getUser = async () => {
    await axios
      .get("/api/user")
      .then(({ data, status }) => {
        if (status === 200) {

          const { role } = data;
          console.log("setting new user, but child component nto picking up on it", data);
          setUser(data);

          switch(role) {
            case 'admin':
              history.push('/timesheets?status=current');
              break;
            default:
              history.push('/timesheets?status=current');
          }
        } else {
          console.log("error, maybe expoered", data);
          return;
        }
      })
      .catch(err => {
        console.log("error tryign to get user deets", err);

        accessToken = null;
        localStorage.setItem("tokens", null);
        setUser();
        // customHistory = ['/login'];
      });
  };

  useEffect(() => {
    if (!accessToken && location.pathname === '/')  return history.push('/login');
    // if (username) return;
    if (!accessToken && location.pathname === '/login') return;
    // if (!accessToken) return history.push('/login');

    // console.log("use effect", username, accessToken);

    // getUser();
  });

  if (accessToken && !username) {

    getUser();
    
    return (
      <div style={{ display: "flex", padding: "10rem" }}>
        <CircularProgress size="5rem" thickness={2} style={{ margin: "5rem auto" }} />
      </div>
    );
  }

  const setToken = async data => {
    localStorage.setItem("tokens", JSON.stringify(data));

    axios.defaults.headers.common["Authorization"] = "Bearer " + data.access_token;
    axios.defaults.headers["Authorization"] = "Bearer " + data.access_token;

    getUser();
  };

  const logout = () => {
    history.push("/login");
    localStorage.setItem("tokens", null);
    axios.defaults.headers.common["Authorization"] = "Bearer ";
    axios.defaults.headers["Authorization"] = "Bearer ";
    accessToken = null;
    setUser();
  };

  const updateUser = data => {
    console.log("update user", data);
    setUser(data);
  };

  return <AuthContext.Provider value={{ user, logout, setToken, updateUser }} {...props} />;
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
