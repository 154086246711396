import React from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, NavLink } from "react-router-dom";

import { useAuth } from "context/auth-context";

import TimesheetItems from "./timesheets";

const drawerWidth = 220;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  toolbar: { ...theme.mixins.toolbar, background: "#fff url('./csa.png') no-repeat center center" },
  logo: {
    textAlign: "center",
    display: "inline-block",
    verticalAlign: "middle"
  },
}));

const LeftNav = function LeftNavigation(props) {
  const classes = useStyles();
  const { user } = useAuth();

  console.log("keep eye on this (unneccessary rerenders)", user);

  return (
    <div>
      <div className={classes.toolbar}></div>
      <Divider />
      {user && user.role === "admin" && (
        <>
          <TimesheetItems />
          <List subheader={<ListSubheader component="div">Users</ListSubheader>}>
            <ListItem
              data-testid="menuActiveUsers"
              button
              key="usersActive"
              component={NavLink}
              activeClassName="Mui-selected"
              to="/users?status=active"
              isActive={(match, location) => location.search === `?status=active`}
            >
              <ListItemText primary="Active" />
            </ListItem>

            <ListItem
              data-testid="menuInactiveUsers"
              button
              key="usersInactive"
              component={NavLink}
              activeClassName="Mui-selected"
              to="/users?status=inactive"
              isActive={(match, location) => location.search === `?status=inactive`}
            >
              <ListItemText primary="Inactive" />
            </ListItem>
            <ListItem
              data-testid="menuUsersMerge"
              button
              key="usersMerge"
              component={NavLink}
              activeClassName="Mui-selected"
              to="/users/merge-list"
            >
              <ListItemText primary="Merge" />
            </ListItem>
          </List>
        </>
      )}
    </div>
  );
};

export default withRouter(LeftNav);
