import React, { useContext, createContext, useState, useMemo, useCallback } from "react";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const SnackBarContext = createContext();

function SnackBarProvider({ children }) {
    const autoHideDuration = 4500;
  const [alerts, setAlerts] = useState([]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlerts([]);
  };

  const addAlert = useCallback((content) => setAlerts((alerts) => [content, ...alerts]), [])
  const value = useMemo(() => ({ addAlert }), [addAlert]);

  console.log(alerts)

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      {alerts.map(({ message, type }) => (
        <Snackbar key={message} anchorOrigin={{ vertical: "top", horizontal: "center" }} open={true} autoHideDuration={autoHideDuration} onClose={handleSnackbarClose}> 
          <Alert onClose={handleSnackbarClose} severity={type}>
            {message}
          </Alert>
        </Snackbar>
      ))}
    </SnackBarContext.Provider>
  );
}

const useSnackBar = () => useContext(SnackBarContext);

export { SnackBarProvider, useSnackBar };
