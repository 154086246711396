import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#1976d2"
    },
    secondary: {
      main: "#dd2c00"
    }
  },
  typography: {
    fontSize: 16
  },

  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: "bold"
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "white"
      }
    }
  }
});
